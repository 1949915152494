<template>
  <div id="dashboard-content">
    <section v-if="hideForm">
      <div class="alert alert-warning">{{ messageHideForm }}</div>
    </section>
    <section v-else id="profile-publisher">
      <h4 class="mb-4">Buku Non Teks</h4>
      <div class="card p-3 border-0 shadow-sm mb-3">
        <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
          <li class="nav-item border">
            <a
              class="nav-link active d-flex align-items-center btn-sm"
              id="pills-data-identitas-buku-tab"
              data-toggle="pill"
              href="#pills-data-identitas-buku"
              role="tab"
              aria-controls="pills-data-identitas-buku"
              aria-selected="true"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                1
              </div>
              Identitas Buku
            </a>
          </li>
          <li class="nav-item pt-1">
            <hr width="50px" />
          </li>
          <li class="nav-item border">
            <a
              class="nav-link d-flex align-items-center btn-sm"
              id="pills-data-pelaku-tab"
              data-toggle="pill"
              href="#pills-data-pelaku"
              role="tab"
              aria-controls="pills-data-pelaku"
              aria-selected="false"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                2
              </div>
              Data Pelaku Perbukuan
            </a>
          </li>
          <li class="nav-item pt-1">
            <hr width="50px" />
          </li>
          <li class="nav-item border">
            <a
              class="nav-link d-flex align-items-center btn-sm"
              id="pills-data-berkas-buku-tab"
              data-toggle="pill"
              href="#pills-data-berkas-buku"
              role="tab"
              aria-controls="pills-data-berkas-buku"
              aria-selected="false"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                3
              </div>
              Data Berkas Buku
            </a>
          </li>
          <li class="nav-item pt-1">
            <hr width="50px" />
          </li>
          <li class="nav-item border">
            <a
              class="nav-link d-flex align-items-center btn-sm"
              id="pills-data-berkas-legalitas-tab"
              data-toggle="pill"
              href="#pills-data-berkas-legalitas"
              role="tab"
              aria-controls="pills-data-berkas-legalitas"
              aria-selected="false"
            >
              <div
                style="width: 25px;height: 25px;"
                class="bg-white text-dark rounded-circle d-flex align-items-center justify-content-center mr-2 border"
              >
                4
              </div>
              Data Berkas Legalitas
            </a>
          </li>
        </ul>
      </div>
      <div class="card p-3 border-0 shadow-sm">
        <div v-if="checkStatusProfile" class="alert alert-warning">
          <span>Silakan isi data profile anda terlebih dahulu. </span>
          <router-link to="/user/profil/publisher" class="text-primary">
            <a>Lengkapi profil</a>
          </router-link>
        </div>
        <form v-else @submit.prevent="createAssesment()">
          <div class="tab-content border-0" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-data-identitas-buku"
              role="tabpanel"
              aria-labelledby="pills-data-identitas-buku-tab"
            >
              <h5 class="font-weight-bold mb-2">Identitas Buku</h5>
              <p class="mb-4">
                Silakan isi formulir di bawah ini dengan hati-hati. Mohon
                cantumkan data sebenar-benarnya untuk memudahkan proses
                penilaian. Tanda bintang (<span class="text-danger">*</span>)
                wajib diisi.
              </p>

              <!-- Form Input Here -->

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="assesmentEventId" class="form-label">
                      Agenda Penilaian
                    </label>
                    <input
                      readonly
                      v-model="assesmentEvent"
                      id="assesmentEventId"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="assesmentEventId" class="form-label">
                      Tipe Buku
                    </label>
                    <input
                      readonly
                      id="assesmentEventId"
                      value="Buku Nonteks"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="judulBuku" class="form-label">
                      Judul Buku <span class="text-danger">*</span>
                    </label>
                    <input
                      id="judulBuku"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan judul buku"
                      v-model="assesment.title"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="isbn" class="form-label">ISBN</label>
                    <input
                      id="isbn"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan ISBN"
                      v-model="assesment.isbn"
                    />
                    <small
                      >ISBN Wajib dikirimkan setelah buku dinyatakan lolos
                      penilaian.</small
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="tahunTerbit" class="form-label"
                      >Tahun Terbit (3 tahun terakhir)</label
                    >
                    <select
                      required
                      v-model="assesment.publication_year"
                      id="tahunTerbit"
                      class="form-control"
                    >
                      <option v-for="(year,index) in publishedYear" :key="index" :value="year">{{year}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="level" class="form-label"
                      >Perjenjangan <span class="text-danger">*</span></label
                    >
                    <select
                      required
                      v-model="assesment.level"
                      id="level"
                      class="form-control"
                    >
                      <option value="A">Pembaca Dini A</option>
                      <option value="B">Pembaca Awal B</option>
                      <option value="C">Pembaca Lanjut C</option>
                      <option value="D">Pembaca Semenjana D</option>
                      <option value="E">Pembaca Mahir E</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="klasifikasi" class="form-label">
                      Klasifikasi <span class="text-danger">*</span>
                    </label>
                    <select
                      required
                      v-model="assesment.purpose"
                      id="klasifikasi"
                      class="form-control"
                    >
                      <option value="Fiksi">Fiksi</option>
                      <option value="Non Fiksi">Non Fiksi</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="imprint" class="form-label">Imprint</label>
                    <input
                      id="imprint"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan nama dagang"
                      v-model="assesment.imprint"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="edition" class="form-label">Edisi Ke-</label>
                    <input
                      id="edition"
                      type="number"
                      class="form-control"
                      placeholder="Masukkan edisi"
                      v-model="assesment.edition"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="jumlahHalPrelim" class="form-label">
                      Jumlah Hal Preliminer <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="jumlahHalPrelim"
                      class="form-control"
                      v-model="assesment.total_prelim_page"
                      placeholder="Masukkan jumlah halaman prelim"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="jumlahHalIsi" class="form-label">
                      Jumlah Hal Isi <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="jumlahHalIsi"
                      class="form-control"
                      v-model="assesment.total_content_page"
                      placeholder="Masukkan jumlah halaman isi"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="jumlahHalPostlim" class="form-label">
                      Jumlah Hal Posliminer <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="jumlahHalPostlim"
                      class="form-control"
                      v-model="assesment.total_ending_page"
                      placeholder="Masukkan jumlah halaman postlim"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="sinopsis" class="form-label">
                      Sinopsis (blurb) <span class="text-danger">*</span>
                    </label>
                    <textarea
                      id="sinopsis"
                      rows="5"
                      class="form-control"
                      placeholder="Masukkan sinopsis"
                      v-model="assesment.synopsis"
                    ></textarea>
                  </div>
                </div>
              </div>

              <!-- Form Input Here -->

              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-secondary text-white"
                  @click="selectTab('#pills-data-pelaku-tab')"
                >
                  Selanjutnya
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-data-pelaku"
              role="tabpanel"
              aria-labelledby="pills-data-pelaku-tab"
            >
              <h5 class="font-weight-bold mb-2">Data Pelaku Perbukuan</h5>
              <p class="mb-4">
                Silahkan isi data-data terkait pelaku perbukuan
              </p>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="penulis" class="form-label">
                      Nama Penulis <span class="text-danger">*</span>
                    </label>
                    <input
                      id="penulis"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan nama penulis"
                      v-model="assesment.author"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="editor" class="form-label">
                      Nama Editor <span class="text-danger">*</span>
                    </label>
                    <input
                      id="editor"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan nama editor"
                      v-model="assesment.editor"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="ilustrator" class="form-label">
                      Nama Ilustrator
                    </label>
                    <input
                      id="ilustrator"
                      type="text"
                      class="form-control"
                      placeholder="Masukkan nama ilustrator"
                      v-model="assesment.illustrator"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-outline-secondary mr-3"
                  @click="selectTab('#pills-data-identitas-buku-tab')"
                >
                  <i class="fa fa-chevron-left"></i>
                  Sebelumnya
                </button>
                <button
                  type="button"
                  class="btn rounded-pill btn-secondary text-white"
                  @click="selectTab('#pills-data-berkas-buku-tab')"
                >
                  Selanjutnya
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-data-berkas-buku"
              role="tabpanel"
            >
              <h5 class="font-weight-bold mb-2">Data Berkas Buku</h5>
              <p class="mb-4">Silahkan isi data-data terkait berkas buku</p>

              <!-- Form Input Here -->
              <div class="row d-flex align-items-center">
                <div class="col-12 mb-4">
                  <div class="form-group">
                    <label for="fileOriginal" class="form-label">
                      Berkas PDF Orisinal dan Lengkap
                      <span class="text-danger">*</span>
                    </label>
                    <FileUploader
                      @select-file="setFile($event, 'file_original')"
                      :uploaded="assesment.file_original"
                      id="file_original"
                    />
                    <div class="text-muted text-right small mt-2">
                      Format PDF, Ukuran Maks 50 Mb
                    </div>
                  </div>
                  <div class="my-1">
                    <button
                      v-if="!loading.file_original"
                      @click="uploadFile('file_original')"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                      id="uploadFileOriginal"
                    >
                      Unggah
                    </button>
                    <button
                      v-if="loading.file_original"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                    >
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-4">
                  <div class="form-group">
                    <label for="fileNoIdentity" class="form-label">
                      Berkas PDF Tanpa Identitas
                      <span class="text-danger">*</span>
                    </label>
                    <FileUploader
                      @select-file="setFile($event, 'file_no_identity')"
                      :uploaded="assesment.file_no_identity"
                      id="file_no_identity"
                    />
                    <div class="text-muted text-right small mt-2">
                      Format PDF, Ukuran Maks 50 Mb
                    </div>
                  </div>
                  <div class="my-1">
                    <button
                      v-if="!loading.file_no_identity"
                      @click="uploadFile('file_no_identity')"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                      id="uploadFileNoIdentity"
                    >
                      Unggah
                    </button>
                    <button
                      v-if="loading.file_no_identity"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                    >
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <!-- Form Input Here -->

              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-outline-secondary mr-3"
                  @click="selectTab('#pills-data-pelaku-tab')"
                >
                  <i class="fa fa-chevron-left"></i>
                  Sebelumnya
                </button>
                <button
                  type="button"
                  class="btn rounded-pill btn-secondary text-white"
                  @click="selectTab('#pills-data-berkas-legalitas-tab')"
                >
                  Selanjutnya
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-data-berkas-legalitas"
              role="tabpanel"
            >
              <h5 class="font-weight-bold mb-2">Data Berkas Legalitas</h5>
              <p class="mb-4">
                Silahkan isi data-data terkait berkas legalitas
              </p>

              <!-- Form Input Here -->
              <div class="row d-flex align-items-center">
                <div class="col-12 mb-4">
                  <div class="form-group">
                    <label for="suratPernyataanPenulis" class="form-label">
                      Surat pernyataan penerbit tentang hak terbit dan keaslian
                      naskah <span class="text-danger">*</span>
                    </label>
                    <FileUploader
                      @select-file="setFile($event, 'surat_pernyataan_penulis')"
                      :uploaded="assesment.surat_pernyataan_penulis"
                      id="surat_pernyataan_penulis"
                    />
                    <div class="text-muted d-flex small mt-2">
                      <div class="mr-auto">
                        Download contoh surat pernyataan penerbit
                        <a
                          href="https://docs.google.com/document/d/1jzwxCKj6tpZy5XLZ2NuwVs6tKjWo05UB/edit?usp=share_link&ouid=101274179921844578487&rtpof=true&sd=true"
                          target="_blank"
                        >
                          <i class="fa fa-download text-primary ml-2"></i>
                        </a>
                      </div>
                      <div>Format PDF, Ukuran Maks 2 Mb</div>
                    </div>
                  </div>
                  <div class="my-1">
                    <button
                      v-if="!loading.surat_pernyataan_penulis"
                      @click="uploadFile('surat_pernyataan_penulis')"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                      id="uploadSuratPernyataanPenulis"
                    >
                      Unggah
                    </button>
                    <button
                      v-if="loading.surat_pernyataan_penulis"
                      type="button"
                      class="btn btn-primary w-100 btn-sm"
                    >
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      @change="!assesment.accept_tnc"
                      v-model="assesment.accept_tnc"
                      type="checkbox"
                      id="accept"
                    />
                    <label class="form-check-label" for="accept">
                      Dengan ini saya menyatakan bahwa data di atas adalah
                      benar. *
                    </label>
                  </div>
                </div>
              </div>
              <!-- Form Input Here -->

              <div
                v-if="$store.state.messageStatusPublisher"
                class="alert alert-success"
              >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="$store.state.messageStatusPublisher = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <span>
                  Terimakasih! Data berhasil diperbaharui. Untuk tahap
                  selanjutnya silahkan akses menu daftarkan buku atau izin
                  cetak.
                </span>
              </div>
              <div class="d-flex justify-content-end mt-4" role="tablist">
                <button
                  type="button"
                  class="btn rounded-pill btn-outline-secondary mr-3"
                  @click="selectTab('#pills-data-berkas-buku-tab')"
                >
                  <i class="fa fa-chevron-left"></i>
                  Sebelumnya
                </button>
                <div class="text-right">
                  <button
                    type="submit"
                    @click="assesment.status = 'draft'"
                    class="btn btn-secondary px-3"
                  >
                    {{
                      $store.state.loadSubmitAssesment
                        ? "Sedang diproses.."
                        : "Simpan sebagai draf"
                    }}
                  </button>
                  <button
                    type="submit"
                    @click="assesment.status = 'publish'"
                    class="btn btn-primary mx-3"
                  >
                    {{
                      $store.state.loadSubmitAssesment
                        ? "Sedang diproses.."
                        : "Simpan permanen"
                    }}
                  </button>
                </div>
              </div>
              <div v-if="assesment.status == 'draft'" class="text-right mx-3 mt-2">
                <small>* Simpan permanen apabila data buku siap untuk dinilai</small>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import FileUploader from "../../../../components/global/dashboard/FileUploader.vue";

export default {
  data() {
    return {
      hideForm: false,
      messageHideForm:
        "Buku ada di dalam tahap penilaian. Untuk sementara tidak bisa diperbaharui.",
      id: this.$route.query.id ?? null,
      checkStatusProfile: false,
      publishedYear: [],
      assesment: {
        status: "draft",
        assessment_event_id: this.$route.query.event_id ?? "",
        title: "",
        book_type: "bnt",
        isbn: "",
        publication_year: "2019",
        purpose: "Fiksi",
        level: "A",
        imprint: "",
        edition: "",
        total_prelim_page: "",
        total_content_page: "",
        total_ending_page: "",
        synopsis: "",
        author: "",
        editor: "",
        illustrator: "",
        file_original: "",
        file_no_identity: "",
        surat_pernyataan_penulis: "",
        accept_tnc: false,
      },
      file: {
        file_original: null,
        file_no_identity: null,
        surat_pernyataan_penulis: null,
      },
      loading: {
        file_original: false,
        file_no_identity: false,
        surat_pernyataan_penulis: false,
      },
      link: {
        file_original: "",
        file_no_identity: "",
        surat_pernyataan_penulis: "",
      },
      required: [
        {
          title: "berkas legalitas",
          tabs: "#pills-data-berkas-legalitas-tab",
          type: "file",
          input: ["surat_pernyataan_penulis"],
        },
        {
          title: "berkas buku",
          tabs: "#pills-data-berkas-buku-tab",
          type: "file",
          input: ["file_original", "file_no_identity"],
        },
        {
          title: "pelaku perbukuan",
          tabs: "#pills-data-pelaku-tab",
          type: "text",
          input: ["author", "editor"],
        },
        {
          title: "identitas buku",
          tabs: "#pills-data-identitas-buku-tab",
          type: "text",
          input: [
            "title",
            "total_prelim_page",
            "total_content_page",
            "total_ending_page",
            "synopsis",
          ],
        },
      ],
      title: "",
      passedValidation: false,
    };
  },
  computed: {
    ...mapState(["eventPenilaian"]),
    assesmentEvent() {
      // Check if there is no data from fetch book detail publisher
      let eventId = this.id
        ? this.assesment.assessment_event_id
        : this.$route.query.event_id;
      let result = this.eventPenilaian?.find((event) => event.id == eventId)
        ?.title;
      return result;
    },
  },
  methods: {
    ...mapActions([
      "submitAssesment",
      "uploadFileAssesment",
      "fetchBookByPublisher",
      "fetchAllEventPenilaian",
      "fetchPublisherProfile",
    ]),
    async checkValidation() {
      let validationFailed = false;

      await Promise.all(
        this.required.map(async (data) => {
          await Promise.all(
            data.input.map(async (name) => {
              if (this.assesment[name] == "") {
                await Swal.fire({
                  icon: "warning",
                  title: `Silahkan lengkapi bagian ${data.title} yang wajib`,
                }).then((status) => {
                  if (status.isConfirmed) {
                    document.querySelector(data.tabs).click();
                    validationFailed = true;
                  }
                });
              } else {
                if (data.type == "file") {
                  if (this.assesment[name] !== this.link[name]) {
                    await Swal.fire({
                      icon: "warning",
                      title: `Silahkan klik tombol unggah pada ${data.title} terlebih dahulu`,
                    }).then((status) => {
                      if (status.isConfirmed) {
                        document.querySelector(data.tabs).click();
                        validationFailed = true;
                      }
                    });
                  }
                }
              }
            })
          );
        })
      );

      // Memanggil metode lain setelah selesai looping data.input.map dan index melewati panjang this.required
      if (!validationFailed) {
        if (!this.assesment.accept_tnc) {
          await Swal.fire({
            icon: "warning",
            title: "Mohon ceklis untuk menyutujui persyaratan dibawah ini",
          });
        } else {
          this.passedValidation = true;
          console.log(this.passedValidation);
        }
      }
    },
    setTemporarySave(e) {
      const judul = e.target.id == "judulBuku" && e.target.value;
      let data = {
        judulBuku: judul,
      };
      setTimeout(() => {
        localStorage.setItem("data-input", JSON.stringify(data));
      }, 2000);
    },
    selectTab(id) {
      document.querySelector(id).click();
    },
    setFile(file, state) {
      this.file[state] = file;
    },
    uploadFile(state) {
      let file = null;
      // Validation check if file null
      if (this.file[state] !== null) {
        this.loading[state] = true;
        file = this.file[state];
      } else {
        switch (state) {
          case "file_original":
            this.title = "File Original";
            break;
          case "file_no_identity":
            this.title = "File Tanpa Identitas";
            break;
          case "surat_pernyataan_penulis":
            this.title = "Surat Pernyataan Penerbit";
            break;
          default:
            break;
        }
        Swal.fire({
          icon: "warning",
          title: `Berkas ${this.title} harus diisi`,
        });
      }
      // Upload file to server
      if (file !== null) {
        this.uploadFileAssesment({
          file,
          eventId: parseInt(this.assesment.assessment_event_id),
          field: state,
        })
          .then((res) => {
            if (res.status == "success") {
              this.assesment[state] = res.file;
              this.link[state] = res.file;
              this.loading[state] = true;
              Swal.fire({
                icon: "success",
                title: "Berhasil diunggah, silahkan isi data selanjutnya",
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: res.message,
              });
            }
          })
          .catch((err) => {
            if (err == 403) {
              Swal.fire({
                icon: "warning",
                title: `Ukuran berkas pada ${this.title} terlalu besar`,
              });
            }
          })
          .finally(() => {
            this.loading[state] = false;
          });
      }
    },
    async createAssesment() {
      await this.checkValidation();
      if (this.passedValidation) {
        this.id != null && (this.assesment["id"] = this.id);
        this.assesment["type_request"] = this.id == null ? "post" : "put";
        await Swal.fire({
          title: "Perhatian",
          icon: "warning",
          text:
            "Pastikan Bapak/Ibu mengklik tombol unggah berkas terlebih dahulu untuk menyetorkan berkas buku, sebelum mengklik tombol simpan",
          showCancelButton: true,
          confirmButtonText: "Sudah, lanjutkan kirim data saya",
          cancelButtonText: "Ok, saya cek kembali",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitAssesment(this.assesment).then((res) => {
              if (res.data.status == "success") {
                this.$store.state.messageStatusAssesment = false;
                this.$store.state.loadSubmitAssesment = false;

                if(this.id) {
                  this.$router.push({ path: `/user/penilaian/revisi/${this.id}` });
                } else {
                  this.$router.push({ path: `/user/penilaian/detail/${res.data.result.id}` });
                }
                
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Terjadi kesalahan",
                });
              }
            });
          }
        });
      }
    },
  },
  created() {
    this.assesment.book_type = "bnt";
    if (this.id) {
      this.fetchBookByPublisher(this.id)
        .then((res) => {
          if (res.data.length !== 0) {
            let data = res.data;
            this.assesment.title = data.title;
            this.assesment.assessment_event_id = data.assessment_event_id;
            this.assesment.book_type = data.book_type;
            this.assesment.isbn = data.isbn;
            this.assesment.publication_year = data.publication_year ?? "2022";
            this.assesment.level = data.level;
            this.assesment.purpose = data.purpose;
            this.assesment.imprint = data.imprint;
            this.assesment.edition = data.edition;
            this.assesment.total_prelim_page = data.total_prelim_page;
            this.assesment.total_content_page = data.total_content_page;
            this.assesment.total_ending_page = data.total_ending_page;
            this.assesment.synopsis = data.synopsis;
            this.assesment.author = data.author;
            this.assesment.editor = data.editor;
            this.assesment.illustrator = data.illustrator;
            this.assesment.file_original = data.file_original;
            this.assesment.file_no_identity = data.file_no_identity;
            this.assesment.surat_pernyataan_penulis =
              data.surat_pernyataan_penulis;
            this.assesment.accept_tnc = data.accept_tnc == 1 ? true : false;
            // Show file if uploaded
            this.link.file_original = data.file_original;
            this.link.file_no_identity = data.file_no_identity;
            this.link.surat_pernyataan_penulis = data.surat_pernyataan_penulis;
            // Check event for hide form
            this.fetchAllEventPenilaian().then((res) => {
              let checkEvent = res.data.find(
                (event) => event.id == this.assesment.assessment_event_id
              );
              if (!checkEvent && this.id === null) {
                this.messageHideForm = "Event tidak tersedia";
                this.hideForm = true;
              }
              
              // Logic hide form for revision
              let step = data.step;
              let status = data.status;
              let allowedRevision =
                (step == "Submission" && status == "Menunggu") ||
                (step == "Submission" && status == "Lengkap tapi Tidak Valid") ||
                (step == "Submission" && status == "Perlu Revisi") ||
                (step == "Prapenilaian" && status == "Layak dengan Perbaikan") ||
                (step == "Pleno" && status == "Revisi Minor") ||
                (step == "Pleno Revisi" && status == "Menunggu");
                (step == "Pleno Revisi" && status == "Perlu Revisi");

              if (checkEvent?.submit_revision == "0") {
                this.messageHideForm = "Data buku sudah tidak dapat diperbaharui.";
                this.hideForm = true;
              } else if (!allowedRevision && data.locked == "1") {
                this.messageHideForm = "Buku sedang dalam proses penilaian dan sementara tidak dapat diperbaharui.";
                this.hideForm = true;
              }

              if (checkEvent.slug !== "bnt") {
                if (this.id) {
                  this.messageHideForm = "Buku tidak ditemukan";
                } else {
                  this.messageHideForm = "Event tidak tersedia";
                }
                this.hideForm = true;
              }
            });
          } else {
            this.messageHideForm = "Buku tidak ditemukan";
            this.hideForm = true;
          }
        })
        .catch(() => {
          this.messageHideForm = "Buku tidak dapat diakses";
          this.hideForm = true;
        });
    } else {
      // Check event for hide form
      this.fetchAllEventPenilaian().then((res) => {
        let checkEvent = res.data.find(
          (event) => event.id == this.assesment.assessment_event_id
        );
        if (!checkEvent && this.id === null) {
          this.messageHideForm = "Event tidak tersedia";
          this.hideForm = true;
        }
        if (checkEvent?.submit_revision == "0") {
          this.messageHideForm =
            "Buku ada di dalam tahap penilaian. Untuk sementara tidak bisa diperbaharui.";
          this.hideForm = true;
        }
        if (checkEvent.slug !== "bnt") {
          if (this.id) {
            this.messageHideForm = "Buku tidak ditemukan";
          } else {
            this.messageHideForm = "Event tidak tersedia";
          }
          this.hideForm = true;
        }
      });
    }

    // Dynamic year, from current year to 2 years ago
    const currentYear = new Date().getFullYear();
    this.publishedYear = [currentYear - 2, currentYear - 1, currentYear];
    this.assesment.publication_year = this.publishedYear[2];

    // Check data profile jika belum diisi
    this.fetchPublisherProfile().then((res) => {
      if (!res.siup) {
        this.checkStatusProfile = true;
      } 
    });
  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit") next();
    else next("/user/home");
  },
  components: { FileUploader },
};
</script>

<style></style>
